import React, {useState} from "react";
import {
    json,
    redirect,
    useActionData,
    useLoaderData,
    useNavigation,
    useSubmit
} from "react-router-dom";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import TimePickerV2 from "./TimePickerV2";
import {doJsonGET} from "../../util/FetchUtils";
import {Alert} from "react-bootstrap";
import {ReactComponent as ErrorIcon} from "../../../assets/images/icons/circle-exclaim-filled.svg"


export const scheduleLoaderV3 = async ({params, request}) => {
    const url = new URL(request.url);
    const message = url.searchParams.get("message");
    console.log("schedule v2 loader hit. message=" + message);

    const response = await doJsonGET(`v2/appointments/${params.appointmentId}/schedule`);
    if (response.ok) {
        let data = await response.json();
        return json({
            message: message,
            data: data,
        });
    } else {
        throw response
    }
}

export const scheduleActionV2 = async ({params, request}) => {
    console.log("schedule v2 action hit");
    let jsonData = await request.json();
    try {
        let response = await fetch(`https://botanical.ink/api/v2/appointments/${params.appointmentId}/schedule`, {
            method: "post",
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (response.ok) {
            let responseData = await response.json();
            if (responseData.message === "FINALIZED") {
                return redirect(`/appointment/${params.appointmentId}`);
            } else if (responseData.message === "RESERVED") {
                return redirect(`/booking/${params.appointmentId}/deposit`);
            } else if (responseData.message === "RESCHEDULED") {
                return redirect(`/appointment/manage/${params.appointmentId}?action=rescheduled`);
            }
        } else if (response.status === 422) {
            // validation issue
            return await response.json();
        } else {
            return json({
                error: response.status,
                message: "Something happened..."
            });
        }
    } catch (error) {
        console.log(error)
        return json({
            error: 500,
            message: "An error occurred while making a request to the server"
        });
    }
}

export const getMessage = (act, ldr) => {
    if (act && act.error) {
        return act.error === "TIME_UNAVAILABLE"
            ? "The time you had selected is no longer available. Please select a new time from the updated calendar."
            : "An error occurred on the server while reserving the selected time. Please try again later."
    } else if (ldr && ldr.message) {
        return ldr.message === "TIME_UNAVAILABLE"
            ? "The time you had selected is no longer available. Please select a new time from the updated calendar."
            : null;
    } else {
        return null;
    }
}

export default function AppointmentScheduleV2() {

    const loaderData = useLoaderData(); // gets the schedule data

    let actionData = useActionData();
    console.log(actionData);
    const submit = useSubmit();
    const navigation = useNavigation();

    const [selectedTime, setSelectedTime] = useState();

    const onSubmit = () => {
        submit({
            startDateTime: selectedTime,
        }, {
            method: "post",
            encType: "application/json",
        });
    }


    return (
        <>
            {getMessage(actionData, loaderData) &&
                <Container>
                    <Alert variant={"danger"} className={"d-flex align-items-center"}>
                        <ErrorIcon className={"fs-4 me-3"}
                                   style={{height: "1em", width: "1em", minWidth: "1em", color: "red"}}/>
                        <span>{getMessage(actionData, loaderData)}</span>
                    </Alert>
                </Container>
            }
            <TimePickerV2
                closed={loaderData.data.closed}
                rawDates={loaderData.data.dates}
                dateTimeObj={loaderData.data.dateTimeMap}
                timeSelectedCallback={setSelectedTime}
            />
            <Container
                className="bg-success d-flex flex-column justify-content-center text-light mb-3 rounded-3 p-3">
                <Button
                    type={"button"} variant={"secondary"}
                    className="w-50 mx-auto"
                    disabled={!selectedTime || navigation.state !== "idle"}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </Container>
        </>
    );
}